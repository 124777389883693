import { model } from '@whizz/api-client';
import { parse } from 'qs';
import React, { memo } from 'react';
import { Route, Switch } from 'react-router-dom';
import LessonPlayer from './LessonPlayer';

const Routes = () => (
  <Switch>
    <Route path="/:id" exact component={({ match }) => {
      const query = parse(window.location.search, { ignoreQueryPrefix: true });
      return (
        <LessonPlayer
          lesson={new model.Lesson({ id: match.params.id })}
          debugMode={'debug' in query}
          randomSeed={query.random}
          viewAll={'all' in query}
        />
      );
    }} />
    <Route component={() => {
      window.location.href = `https://${process.env.REACT_APP_HOSTNAME_WWW}`;
      return null;
    }} />
  </Switch>
);

export default memo(Routes);
