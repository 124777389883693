import { Container, Card, CardContent, Grid, Hidden, Modal, Typography, CardHeader, Button } from '@material-ui/core';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { makeStyles, useTheme } from '@material-ui/styles';
import { PlayCircleFilledRounded as PlayArrow } from '@material-ui/icons';
import { model } from '@whizz/api-client';
import { useI18n } from '@whizz/react-i18n';
import { default as BaseLessonPlayer } from '@whizz/react-lesson-player';
import { MathsWhizzLogo } from '@whizz/react-branding';
import React, { memo, useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import Loading from './Loading';
import Orientation from '../assets/orientation.svg';

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(6),
  },
  results: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
  },
  orientation: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    width: '50%',
    transform: 'translate(-50%, -50%)',
    outline: 'none',
  },
}));

const previewStyles = makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(2),
  },
  thumbnail: {
    width: '100%',
    height: 'auto',
    borderRadius: '0.5rem',
  },
  appVersion: {
    margin: '1rem',
    color: theme.palette.grey[700],
  },
}));
const secondsToHoursAndMinutes = (seconds) => [ Math.floor(seconds / 60), Math.floor(seconds % 60) ].map((x) => (x + '').padStart(2, '0')).join(':');

const Preview = ({ lessonResult, onClick }) => {
  const { locale, translate } = useI18n();
  const theme = useTheme();
  const { lesson } = lessonResult;
  const { topic, title } = lesson;
  const classes = previewStyles();
  const mobile = useMediaQuery(theme.breakpoints.down('xs'));

  return (
    <Container maxWidth='md' className={classes.root}>
      <Card>
        <CardHeader title={topic.name}/>
        <CardContent>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={4}>
              <img
                className={classes.thumbnail}
                src={lesson.thumbnail}
                alt={title}
                width='307'
                height='222'
              />
            </Grid>
            <Grid item xs={12} sm={8}>
              <Typography
                color='textSecondary'
                variant='h6'
              >{title}</Typography>
            </Grid>
            <Hidden xsDown>
              <Grid item sm/>
            </Hidden>
            <Grid item xs={12} sm={4} align='center'>
              <Button
                variant='contained'
                color='primary'
                size='large'
                fullWidth={mobile}
                startIcon={<PlayArrow />}
                onClick={onClick}
              >{translate('LessonPlayer.START')}</Button>
            </Grid>
            <Grid item xs={12} sm align={mobile ? 'center' : 'right'}>
              {/* eslint-disable-next-line react/jsx-no-target-blank */}
              <a href='https://www.whizz.com' target='_blank'>
                <MathsWhizzLogo locale={locale.id}/>
              </a>
            </Grid>
          </Grid>
        </CardContent>
        { process.env.REACT_APP_VERSION && <Typography variant="caption" className={classes.appVersion}>{translate('App.APP_VERSION', { version: process.env.REACT_APP_VERSION })}</Typography> }
      </Card>
    </Container>
  );
};

const LessonPlayer = ({ lesson, ...rest }) => {

  const classes = useStyles();
  const { locale, translate } = useI18n();
  const landscape = useMediaQuery('(orientation: landscape)');
  const [ showPreview, setShowPreview ] = useState(true);
  const [ lessonResult, setLessonResult ] = useState(null);

  useEffect(() => {

    if (lessonResult) {
      return;
    }

    lesson.setLocale(locale).hydrate()
      .then((lesson) => {
        setLessonResult(new model.LessonResult({ lesson }));
      })
      .catch(() => {});

  }, [ lesson, lessonResult, locale ]);

  if (!lessonResult) {
    return <Loading />;
  }

  if (showPreview) {
    return <Preview lessonResult={lessonResult} onClick={() => setShowPreview(false)}/>;
  }

  if (lessonResult.incomplete) {
    return (
      <>
        <BaseLessonPlayer {...rest} lessonResult={lessonResult} closeLessonCallback={setLessonResult} />
        <Modal open={!landscape}>
          <img src={Orientation} alt="orientation" className={classes.orientation} />
        </Modal>
      </>
    );
  }

  const score = Math.round(lessonResult.score || 0); // TODO: Score should be set to 0 by react-lesson-player
  const helps = lessonResult.legacy.help1 + lessonResult.legacy.help2 + lessonResult.legacy.help3; // TODO: Helps should not be stored in the legacy attribute by react-lesson-player

  return (
    <Container maxWidth="sm" className={classes.root}>
      <Card>
        <CardContent>
          <Typography color="textSecondary" variant="h5">{lessonResult.lesson.test ? translate('LessonPlayer.COMPLETED_TITLE_TEST') : translate('LessonPlayer.COMPLETED_TITLE_EXERCISE')}</Typography>
          <Grid container className={classes.results}>
            <Grid item xs={4}>
              <Typography variant="overline" component="div" color="textSecondary" align="center">{translate('LessonPlayer.SCORE', { count: score })}</Typography>
              <Typography variant="h4" align="center">{`${score}%`}</Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography variant="overline" component="div" color="textSecondary" align="center">{translate('LessonPlayer.TIME_TAKEN', { count: lessonResult.time_taken.total })}</Typography>
              <Typography variant="h4" align="center">{secondsToHoursAndMinutes(lessonResult.time_taken.total)}</Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography variant="overline" component="div" color="textSecondary" align="center">{translate('LessonPlayer.HELP', { count: helps })}</Typography>
              <Typography variant="h4" align="center">{helps}</Typography>
            </Grid>
          </Grid>
          <Typography variant="body1">{lessonResult.lesson.test ? translate('LessonPlayer.COMPLETED_MESSAGE_TEST') : translate('LessonPlayer.COMPLETED_MESSAGE_EXERCISE')}</Typography>
        </CardContent>
      </Card>
    </Container>
  );

};

export default withRouter(memo(LessonPlayer));
