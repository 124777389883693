import { client } from '@whizz/api-client';
import { apiError } from '@whizz/logger';
import { useEffect } from 'react';

const LogApiErrors = ({ children }) => {

  useEffect(() => {

    const interceptor = client.http().interceptors.response.use(null, (err) => {
      apiError(err);
      return Promise.reject(err);
    });

    return () => {
      client.http().interceptors.response.eject(interceptor);
    };

  }, []);

  return children;

};

export default LogApiErrors;
