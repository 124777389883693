import { createMuiTheme, CssBaseline } from '@material-ui/core';
import { ThemeProvider } from '@material-ui/styles';
import { muiTheme } from '@whizz/react-branding';
import { I18nProvider } from '@whizz/react-i18n';
import * as luxon from 'luxon';
import { parse } from 'qs';
import React, { memo } from 'react';
import { BrowserRouter } from 'react-router-dom';
import translations from '../translations';
import ErrorBoundaryWrapper from './ErrorBoundaryWrapper';
import Loading from './Loading';
import LogApiErrors from './LogApiErrors';
import Routes from './Routes';

export const STORAGE_NAME_LOCALE = 'locale';

const locale = parse(window.location.search, { ignoreQueryPrefix: true }).locale || window.sessionStorage.getItem(STORAGE_NAME_LOCALE) || undefined;
if (locale) {
  window.sessionStorage.setItem(STORAGE_NAME_LOCALE, locale);
}

const App = () => (
  <ThemeProvider theme={createMuiTheme(muiTheme)}>
    <CssBaseline />
    <I18nProvider locale={locale} loadingComponent={<Loading />} luxon={luxon} translations={translations}>
      <ErrorBoundaryWrapper>
        <LogApiErrors>
          <BrowserRouter>
            <Routes />
          </BrowserRouter>
        </LogApiErrors>
      </ErrorBoundaryWrapper>
    </I18nProvider>
  </ThemeProvider>
);

export default memo(App);
