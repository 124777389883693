import { CircularProgress } from '@material-ui/core';
import React, { memo } from 'react';

const Loading = ({
  alignment = 'center',
  disableMargins = false,
  size = 44,
}) => (
  <div style={{
    margin: !disableMargins ? `${size / 2}px ${size}px` : 0,
    textAlign: alignment,
  }}>
    <CircularProgress size={size} />
  </div>
);

export default memo(Loading);
