import ErrorBoundary from '@whizz/react-error-boundary';
import { useI18n } from '@whizz/react-i18n';
import React from 'react';

const ErrorBoundaryWrapper = ({ children, ...rest }) => {

  const { locale } = useI18n();

  return (
    <ErrorBoundary {...rest} locale={locale}>
      {children}
    </ErrorBoundary>
  );

};

export default ErrorBoundaryWrapper;
