import enAe from './en-ae.json';
import enGb from './en-gb.json';
import enKe from './en-ke.json';
import enNz from './en-nz.json';
import enUs from './en-us.json';
import esMx from './es-mx.json';
import frCd from './fr-cd.json';
import ruRu from './ru-ru.json';
import swKe from './sw-ke.json';
import thTh from './th-th.json';

export default {
  'en-ae': enAe,
  'en-gb': enGb,
  'en-ke': enKe,
  'en-nz': enNz,
  'en-us': enUs,
  'es-mx': esMx,
  'fr-cd': frCd,
  'ru-ru': ruRu,
  'sw-ke': swKe,
  'th-th': thTh,
};
